<template>
  <div class="OfficialWeb">
    <van-nav-bar fixed title="关于厚仁" left-text="返回" class="navBar" left-arrow @click-left="onClickLeft"> </van-nav-bar>
    <div class="tabWrap">
      <van-tabs
        v-model="active"
        :border="true"
        offset-top="46"
        sticky
        swipeable
        scrollspy
        title-active-color="#00a465"
        color="#00a465"
        background="#fff"
      >
        <van-tab title="公司介绍">
          <tab-screen1></tab-screen1>
        </van-tab>
        <van-tab title="服务介绍">
          <tab-screen2></tab-screen2>
        </van-tab>
        <van-tab title="厚仁优势">
          <tab-screen3></tab-screen3>
        </van-tab>
        <van-tab title="联系我们">
          <tab-screen4></tab-screen4>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
import TabScreen1 from './TabScreen1';
import TabScreen2 from './TabScreen2';
import TabScreen3 from './TabScreen3';
import TabScreen4 from './TabScreen4';
import { backInitMixin } from '@/utils/mixin';
export default {
  components: {
    TabScreen1,
    TabScreen2,
    TabScreen3,
    TabScreen4
  },
  mixins: [backInitMixin],
  props: {},
  data() {
    return {
      active: 0
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
};
</script>

<style scoped lang="less">
/deep/ .van-nav-bar__title {
  color: #fff;
}
/deep/ .van-nav-bar__left .van-icon-arrow-left {
  color: #fff;
}
/deep/ .van-nav-bar__left .van-nav-bar__text {
  color: #fff;
}
.navBar {
  background: #00a465;
}
.tabWrap {
  margin-top: 46px;
}
.OfficialWeb {
  background: #f9f9f9;
  padding-bottom: 30px;
}
</style>
