<template>
  <div class="screen">
    <top-header color="#00a465" title="服务介绍"></top-header>
    <div class="serve">
      <div class="con_1">
        <h5>{{ con_1.label }}</h5>
        <div class="linkWrap">
          <div v-for="item in con_1.con" :key="item.label"> {{ item.label }}</div>
          <!-- <a :href="item.url" v-for="item in con_1.con" :key="item.label" target="_blank"> {{ item.label }}</a> -->
        </div>
      </div>
      <div class="con_2">
        <h5>{{ con_2.label }}</h5>
        <div class="linkWrap">
          <div v-for="item in con_2.con" :key="item.label">
            {{ item.label }}
          <!-- <a :href="item.url" v-for="item in con_2.con" :key="item.label" target="_blank"> {{ item.label }} -->
          </div>
        </div>
      </div>
      <div class="con_3">
        <h5>{{ con_3.label }}</h5>
        <div class="linkWrap">
          <div v-for="item in con_3.con" :key="item.label"> {{ item.label }}</div>
          <!-- <a :href="item.url" v-for="item in con_3.con" :key="item.label" target="_blank"> {{ item.label }}</a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from '@/components/TopHeader'
export default {
  components: {
    TopHeader
  },
  props: {

  },
  data () {
    return {
      con_1: {
        label: '转升学',
        con: [
          {
            label: '美国研究生申请',
            url: 'https://www.wholeren.com/services/graduate-placement/ '
          },
          {
            label: '美国大学转学',
            url: 'https://www.wholeren.com/services/college-transfer/  '
          },
          {
            label: '美国大学申请',
            url: 'https://www.wholeren.com/services/school-placement/ '
          },
          {
            label: '美国初高中申请/转学',
            url: 'https://www.wholeren.com/services/high-school/ '
          },
          {
            label: 'Top50名校跃升计划',
            url: 'https://www.wholeren.com/services/top50/'
          }
        ]
      },

      con_2: {
        label: '背景提升/辅导/寄宿',
        con: [
          {
            label: '学术辅导',
            url: 'https://www.wholeren.com/services/academic-support/ '
          },
          {
            label: 'JobUpper职业规划',
            url: 'https://www.jobupper.com/'
          },
          {
            label: 'ReadyAI人工智能教育',
            url: 'https://www.readyai.org/'
          },
          {
            label: '美国寄宿家庭服务',
            url: 'http://www.homestaynet.us/zh/ '
          }
        ]
      },

      con_3: {
        label: '学业恢复',
        con: [
          {
            label: '学术紧急应对',
            url: 'https://www.wholeren.com/services/dismiss-service/ '
          },
          {
            label: 'F-1签证辅导',
            url: 'https://www.wholeren.com/services/visa-service/'
          },
          {
            label: '护学星高端定制',
            url: 'https://www.wholeren.com/services/%e7%95%99%e7%be%8e%e6%8a%a4%e5%ad%a6%e6%98%9f/'
          }

        ]
      }
    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.screen{
  margin-top:10px;
    padding:0 16px;
}
.img{
    width:100%;
    background:#fbfbfb
}
.intro{
    p{
        margin-bottom:10px;
        color:#333
    }
}
.serve{
    h5{
        color:#333;
        font-size:16px;
        text-align:left;
        margin-bottom:15px;
        margin-top:5px;
    }
    .linkWrap{
        display:flex;
        justify-content: space-between;
        flex-wrap:wrap;
        div{
          width:calc(50% - 8px);
          border:1px solid #ccc;
          padding:5px 0;
          margin-bottom:20px;
          color:#666;
          border-radius:5px;
          display:flex;
          justify-content: center;
          align-items: center;

        }
    }

}
</style>
