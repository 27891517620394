<template>
  <div class="screen">
    <top-header color="#00a465" title="厚仁优势"></top-header>
    <div class="conWrap">
      <div v-for="(item,index) in numData" :key="index" class="itemWrap">
        <p class="num">{{ item.num }} <span v-if="item.add">+</span> </p>
        <p class="tips">{{ item.tips }}</p>
        <p class="iconWrap"> ———— <a-icon type="star" theme="filled"></a-icon> ———— </p>
        <p class="label">{{ item.label1 }}</p>
        <p class="label">{{ item.label2 }}</p>
      </div>
    </div>
    <top-header color="#00a465" title="权威认证"></top-header>
    <div class="certWrap">
      <div v-for="(item,index) in cert" :key="index" class="certItemWrap" @click="showModal(index)">
        <img :src="item.imgUrl" alt="">
        <div>
          <p class="name">{{ item.title }}</p>
          <p class="label">{{ item.label }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'
import TopHeader from '@/components/TopHeader'
export default {
  components: {
    TopHeader
  },
  props: {

  },
  data () {
    return {

      numData: [
        {
          num: '24,000',
          add: true,
          tips: '学生被帮助',
          label1: '坚持把学生放在第一位，',
          label2: '实战经验丰富'
        },
        {
          num: '3,500',
          add: true,
          tips: '美国院校资源',
          label1: '直通美国院校招生官，',
          label2: '活跃于主流国际教育舞台'
        },
        {
          num: '100%',
          tips: '美国名校服务团队',
          label1: '服务导师均出自美国名校，',
          label2: '亲身留美经验成为指路明灯'
        },
        {
          num: '5大',
          tips: '美国分部',
          label1: '北美各地办公室全方位支持，',
          label2: '陪护学生安全与成就'
        }
      ],
      cert: [
        {
          imgUrl: require('@/assets/searchArea/cert_1.png'),
          title: '美国国际招生协会',
          label: 'AIRC权威认证 '
        },
        {
          imgUrl: require('@/assets/searchArea/cert_2.png'),
          title: '国际教育旅行标准委员会',
          label: 'CSIET权威认证 '
        },
          {
          imgUrl: require('@/assets/searchArea/cert_3.png'),
          title: '国际教育和博览咨询委员会',
          label: 'ICEF认证 '
        },
        {
          imgUrl: require('@/assets/searchArea/cert_4.png'),
          title: '美国职业生涯发展协会',
          label: 'NCDA认证 '
        }
      ]
    }
  },
  computed: {
    imgData () {
      return this.cert.map(item => {
        return item.imgUrl
      })
    }
  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    showModal (index) {
      ImagePreview({
        images: this.imgData,
        startPosition: index

      })
    }
  }
}
</script>

<style scoped lang="less">
.screen{
    padding:0 16px;
}
.conWrap{
  display:flex;
  justify-content: space-between;
  flex-wrap:wrap;
  .itemWrap{
    padding:10px 0;
    width:48%;
    background: #fff;
    margin-bottom:20px;
    display:flex;
    flex-direction: column;
    align-items:center;
    p{
      margin-bottom:10px;
      text-align:center;
      font-family: Arial, 微软雅黑, 黑体;
    }
    .num{
      font-size:32px;
      color:#39ba82;
      font-weight:bold;
      margin-bottom:0;

      span{
        font-size:24px;
      }
    }
    .tips{
        color:#39ba82;
    }
    .iconWrap{
      display:flex;
      justify-content: center;
      align-items:center;
      color:#ccc;
      i{
        color:#39ba82;
        margin:0 10px;
      }

    }
     .label{
        font-size:12px;
      }

  }
}
.certItemWrap{
  display:flex;
  align-items: center;
    margin-bottom:15px;
  img{
    width:60px;
    height:60px;
  }
  div{
    margin-left:15px;

    p{
      margin-bottom:0;
    }
    .name{
      font-size:14px;
      color:#333;
      font-weight:bold;
      margin-bottom:5px;
    }
    .label{
       font-size:14px;
      color:#39ba82;
    }

  }

}
</style>
