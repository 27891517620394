<template>
  <div class="screen">
    <!-- <img src="@/assets/searchArea/webLogo.png" alt="" class="img" /> -->
    <div class="html-wrap">
      <div v-html="conObj.page_header"></div>
      <div v-html="conObj.page_body"></div>
      <div v-html="conObj.page_footer"></div>
    </div>
    <!-- <top-header color="#00a465" title="公司介绍"></top-header>
    <div class="intro">
      <p>
        美国厚仁教育成立于2010年，是行业领先的教育咨询服务公司。扎根美国，服务中国留学生，业务覆盖留美升学，转学，学业恢复，护学，辅导，背景提升，就业指导，寄宿家庭，人工智能教育。
      </p>
      <p>厚仁始终以学生为中心，做对的事情，已帮助上万名留学生，发掘潜能，走向优秀。</p>
      <p>
        集团至今已有10年历史，总部在美国宾州匹兹堡，美国5个办公室，中国3个办公室。集团旗下有7个子公司，80位全时双语员工，有AIRC和CSIET的专业认证。曾接受CCTV、纽约时报、华尔街日报等主流媒体专访，是最了解中美教育差异的专家，是指导留美学生成功的导师团队。
      </p>
    </div> -->
    <div class="letterWrap">
      <a-button @click="goToLetter" type="primary">【致留学生的一封信】</a-button>
    </div>
    <img :src="imgUrl" alt="" class="img" @click="showModal()" />
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
import TopHeader from '@/components/TopHeader';
export default {
  components: {
    TopHeader
  },
  props: {},

  data() {
    return {
      imgUrl: require('@/assets/searchArea/intro.jpeg'),
      conObj: {}
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getData();
  },
  mounted() {},
  methods: {
    showModal() {
      ImagePreview({
        images: [this.imgUrl]
      });
    },
    goToLetter() {
      this.$router.push('letter');
    },
    getData() {
      this.$api['searchArea/relation']().then(res => {
        if (res.message) {
          this.conObj = res.message;
        }
      });
    }
  }
};
</script>

<style scoped lang="less">
.screen {
  padding: 20px 16px;
}
.img {
  width: 100%;
}
.intro {
  p {
    margin-bottom: 10px;
    color: #333;
  }
}
.letterWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  button {
    border: 1px solid #00a465;
    background: #fff;
    color: #00a465;
  }
}
</style>
