<template>
  <div class="screen">
    <top-header color="#00a465" title="联系我们"></top-header>
    <div v-for="(item, index) in contact" :key="index" class="itemWrap">
      <p class="label">{{ item.label }}</p>
      <div>
        <a :href="item.url" v-if="item.url" target="_blank">{{ item.value }}</a>
        <p v-else>{{ item.value }}</p>
        <p v-if="item.value_2">{{ item.value_2 }}</p>
      </div>
    </div>
    <div class="imgWrap">
      <img :src="qrcodeItem.qrcode || imgUrl" alt="" @click="showModal()" />
    </div>
    <top-header color="#00a465" title="办公地址"></top-header>
    <div class="location">
      <p>
        美国 | 匹兹堡 洛杉矶 奥兰治 旧金山 纽约
      </p>
      <p>中国 | 北京 武汉 广州</p>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant';
import TopHeader from '@/components/TopHeader';
export default {
  components: {
    TopHeader
  },
  props: {},
  data() {
    return {
      contact: [
        {
          label: '官网',
          value: 'www.wholeren.com',
          url: 'https://www.wholeren.com'
        },
        {
          label: '邮箱',
          value: 'info@wholeren.com'
        },
        {
          label: '电话',
          value: '+1 (412) 756-3137（美国拨打）',
          value_2: '+86 (010) 5387-5758（中国拨打）'
        },
        {
          label: '微信',
          value: 'wholerenguru3（厚仁学术哥）'
        }
      ],
      imgUrl: require('@/assets/searchArea/contact.jpeg'),
      qrcode: {}
    };
  },
  computed: {
    qrcodeItem() {
      const qrcodeArr = this.qrcode.qrcode || [];
      const qrcodeItem = qrcodeArr[0] || {};
      return qrcodeItem;
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.getData();
  },
  methods: {
    showModal() {
      ImagePreview({
        images: [this.imgUrl]
      });
    },
    getData() {
      this.$api['searchArea/qrcode']()
        .then(res => {
          if (res.message) {
            this.qrcode = res.message;
          }
        })
        .catch(err => {
          console.log('err', err);
        });
    }
  }
};
</script>

<style scoped lang="less">
.screen {
  padding: 0 16px;
}
.itemWrap {
  display: flex;
  p {
    margin-bottom: 10px;
    color: #333;
  }
  a {
    color: #39ba82;
  }
  .label {
    margin-right: 10px;
  }
}
.imgWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  img {
    width: 180px;
  }
}
.location {
  p {
    margin-bottom: 10px;
    color: #333;
  }
}
</style>
